import React from 'react';


import './style.scss';



const Banner = () => (
	<div className="banner py-5 px-5 w-100"> 

	<h3>Call to Schedule Service</h3>
	<h2>732-237-2782</h2>

</div>
);

export default Banner;
